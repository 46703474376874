import { WidgetBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetBuilder';
import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Fast_Gallery_GFPP_Main_Action_1'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        const galleryId = (
          await editorSDK.document.controllers.getData('', {
            controllerRef: componentRef,
          })
        ).config.galleryId;
        editorSDK.editor
          .openModalPanel('', {
            title: t('OImages_Main_Title'),
            url: getPanelUrl('fastGalleryWidget', 'FastGalleryOrganizeMedia'),
            height: '90%' as any,
            width: '90%' as any,
            initialData: {
              componentRef,
              galleryId,
            },
            centered: true,
          })
          .then(() => {
            editorSDK.document.application.livePreview.refresh('', {
              shouldFetchData: true,
              source: '',
            });
          });
      },
    })
    .set('mainAction2', {
      label: t('Fast_Gallery_GFPP_Main_Action_2'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        const initialSelectedPresetId = (
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef,
          })
        ).style;
        editorSDK.editor.openModalPanel('', {
          url: getPanelUrl('fastGalleryWidget', 'FastGalleryPresetsPanel'),
          height: '90%' as any,
          width: '90%' as any,
          initialData: {
            componentRef,
            initialSelectedPresetId,
          },
          shouldHideHeader: true,
          centered: true,
        });
      },
    })
    .set('design', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('settings', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('layout', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('connect', {
      behavior: 'HIDE',
    })
    .set('widgetPlugins', { behavior: 'HIDE' })
    .set('add', {
      behavior: 'HIDE',
    });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI);

  widgetBuilder.set({
    displayName: 'Fast Gallery',
    nickname: 'fastGalleryWidget',
  });
};

import { WidgetPropertyType } from '@wix/yoshi-flow-editor/build/cjs/blocks';

export enum VIEW_MODES {
  EDITOR = 'EDITOR',
  PREVIEW = 'PREVIEW',
  SITE = 'SITE',
}

export enum MEDIA_MANAGER_MEDIA_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  PRO_GALLERY = 'PRO_GALLERY',
}

export const ExpandModPolyWidget = '5ae9a59b-f087-4fde-9a45-137af3d9b11f';
export const appDefinitionId = '785199b4-c741-4d19-ad2f-060695514c78';

export const expandModePluginProps = {
  // Here you can define your widget props
  items: {
    type: [] as any,
    title: 'Gallery Items',
    defaultValue: [],
  },
  currentIndex: {
    type: WidgetPropertyType.NUMBER,
    title: 'Gallery Id',
    defaultValue: -1,
  },
};

export enum ExpandModeMainWidgetEvents {
  CloseExpandModeClicked = 'closeExpandModeClicked',
}

import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const addFastGalleryEditorElement = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  // install the fast gallery editor element
  const galleryDefinition =
    await editorSDK.document.components.createDefinition('', {
      componentType: 'wixui.FastGallery',
    });
  const fastGalleryCompRef = await editorSDK.document.components.add('token', {
    componentDefinition: {
      ...galleryDefinition,
      layout: {
        x: 100,
        y: 80,
        height: 40,
        width: 150,
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 280,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'px',
            value: 700,
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
          margins: {
            left: { type: 'px', value: 0 },
            right: { type: 'px', value: 0 },
            top: { type: 'px', value: 0 },
            bottom: { type: 'px', value: 0 },
          },
        },
      },
    },
    pageRef: containerRef,
  });

  return fastGalleryCompRef;
};

export const addFastGalleryExpandContainer = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  // install expand container
  const expandDefinition = await editorSDK.document.components.createDefinition(
    '',
    {
      componentType: 'wixui.ExpandedFastGalleryOverlay',
    },
  );
  const expandCompRef = await editorSDK.document.components.add('token', {
    componentDefinition: {
      ...expandDefinition,
      layout: {
        x: 100,
        y: 80,
        height: 40,
        width: 150,
      },
    },
    // fastGalleryCompRef
    pageRef: containerRef,
  });
  return expandCompRef;
};

export const addGalleryWidget = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
): Promise<ComponentRef> => {
  const widgetRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: '8cba69eb-8291-4aba-8be6-6a7f3428e18b',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 100,
      width: 100,
      x: 0,
      y: 100,
    },
    presetIds: {
      style: 'variants-lgnn37aw',
      layout: 'variants-lgnn37aw',
    },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 50,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
  });

  return widgetRef;
};

export const addExpandWidget = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    containerRef,
    widgetId: '01f0b912-4854-46f4-9302-aa8fbf0f6afd',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 100,
      width: 100,
      x: 0,
      y: 100,
    },
    presetIds: {
      style: 'variants-lyejjuc61',
      layout: 'variants-lyejjuc61',
    },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 50,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
  });
};

export const addExpandModeWidget = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    containerRef,
    widgetId: '2a65c4c4-b099-4310-a6ff-36ae3649d92d',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 100,
      width: 100,
      x: 0,
      y: 100,
    },
    presetIds: {
      style: 'variants-m1eyuv7g1',
      layout: 'variants-m1eyuv7g1',
    },
    layouts: {
      type: 'SingleLayoutData',
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'percentage',
          value: 100,
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 100,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
        margins: {
          left: { type: 'px', value: 0 },
          right: { type: 'px', value: 0 },
          top: { type: 'px', value: 0 },
          bottom: { type: 'px', value: 0 },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
    },
  });
};

import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import {
  expandModePluginProps,
  ExpandModeMainWidgetEvents as Events,
} from '../../constants/generalConstants';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'expandPlugin1',
  props: expandModePluginProps,
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    [Events.CloseExpandModeClicked]: {
      description: 'Fired when close button clicked',
    },
  },
});

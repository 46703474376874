import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ExpandModPolyWidgetPresetsArray } from './constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  widgetBuilder.set({
    displayName: 'Widget ExpandModPolyWidget',
    nickname: 'ExpandModPolyWidgetWidget',
  });
  widgetBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
    widgetPresetsBuilder.setPresets(ExpandModPolyWidgetPresetsArray);
  });
  widgetBuilder
    .gfpp()
    .set('add', { behavior: 'HIDE' })
    .set('widgetPlugins', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' });
};

import {
  ComponentRef,
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { appDefinitionId } from '../constants/generalConstants';

interface GetSlotDataResponse {
  slotCompRef: ComponentRef;
  slotInnerWidgetRef: ComponentRef;
  slotWidgetPluginId: string;
}

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  const switchSlotsWidgetPlugin = async ({
    slotCompRef,
    pluginId,
  }: {
    slotCompRef: ComponentRef;
    pluginId: string;
  }) => {
    await editorSDK.tpa.widgetPlugins.removeWidgetPlugin('', {
      slotCompRef,
    });
    console.log('removed widget plugin');
    await editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
      widgetPluginPointer: {
        appDefinitionId, // fast poc widget
        widgetId: pluginId,
      },
      slotCompRef,
    });
    console.log('switch widget plugin');
  };

  const getSlotData = async (
    widgetRef: ComponentRef,
  ): Promise<GetSlotDataResponse> => {
    const slotsData = (
      await editorSDK.tpa.widgetPlugins.getWidgetSlots('', {
        widgetRef,
      })
    ).filter((slot) => !!slot) as any[];
    const slotData = slotsData[0];
    const slotCompRef = slotData.compRef;
    const slotInnerWidgetRef = (
      await editorSDK.components.getChildren('', {
        componentRef: slotCompRef,
      })
    )[0];
    const slotWidgetPluginId = slotData.pluginInfo.widgetId;

    return { slotCompRef, slotInnerWidgetRef, slotWidgetPluginId };
  };

  const checkIfWidgetPresetIsAlreadySet = async ({
    presetId,
    slotInnerWidgetRef,
  }: {
    presetId: string;
    slotInnerWidgetRef: ComponentRef;
  }): Promise<boolean> => {
    const currentWidgetPreset =
      await editorSDK.application.appStudioWidgets.getPreset('', {
        componentRef: slotInnerWidgetRef,
      });

    if (currentWidgetPreset?.style === presetId) {
      console.log('widget preset already set');
      return true;
    } else {
      return false;
    }
  };
  const setSlotsWidgetPluginPreset = async ({
    presetIdToSet,
    slotInnerWidgetRef,
  }: {
    presetIdToSet: string;
    slotInnerWidgetRef: ComponentRef;
  }): Promise<void> => {
    await editorSDK.components.refComponents.removeAllOverrides('', {
      componentRef: slotInnerWidgetRef,
    });
    console.log('removed all overrides');
    await editorSDK.application.appStudioWidgets.changePreset('', {
      componentRef: slotInnerWidgetRef,
      stylePresetId: presetIdToSet,
      layoutPresetId: presetIdToSet,
    });
    console.log('changed preset');
  };

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      const { preset, componentRef } = event.detail;
      const widgetRef = (
        await editorSDK.components.getAncestors('', {
          componentRef,
        })
      )[0];
      const [externalPresetPrefix, presetWidgetPluginId, presetId] =
        preset.split('#');

      const { slotCompRef, slotInnerWidgetRef, slotWidgetPluginId } =
        await getSlotData(widgetRef);
      const isSameSlotsPlugin = presetWidgetPluginId === slotWidgetPluginId;

      if (isSameSlotsPlugin) {
        const isWidgetPresetIsAlreadySet =
          await checkIfWidgetPresetIsAlreadySet({
            presetId,
            slotInnerWidgetRef,
          });
        if (isWidgetPresetIsAlreadySet) {
          return;
        }
        await setSlotsWidgetPluginPreset({
          presetIdToSet: presetId,
          slotInnerWidgetRef,
        });
      } else {
        await switchSlotsWidgetPlugin({
          slotCompRef,
          pluginId: presetWidgetPluginId,
        });
        const { slotInnerWidgetRef: newSlotInnerWidgetRef } = await getSlotData(
          widgetRef,
        );
        setSlotsWidgetPluginPreset({
          presetIdToSet: presetId,
          slotInnerWidgetRef: newSlotInnerWidgetRef,
        });
      }
    },
  );
};
